<template>
    <div class="PdfView">
        <div id="subdiv" v-if="pdfurl !=null" >
            <pdf  v-for="i in numPages" :key="i"  :src="src" :page="i"></pdf>
        </div>
    </div>

  </template>
  
  <script>
  import pdf from 'vue-pdf'

  export default {
    name: "PdfView",
    props: {
    },
    components: {
		pdf
	},
    data(){
        return {
            src:null,
            pdfurl:null,
            numPages:1,
        }
    },
    mounted(){
        
        this.pdfurl  = window.location.protocol+"//"+window.location.host+'/api/files/pdf/get?filepath='+encodeURIComponent(this.$route.query.pdfurl)

        var loadingTask = pdf.createLoadingTask(this.pdfurl);
        this.src = loadingTask

        this.src.promise.then(pdf => {

        this.numPages = pdf.numPages;
        });
    },
    methods:{
        printcontent(){
            public_print("contentdiv",1);
        }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
      .PdfView {
        margin:20px auto;
        padding: 5%;
      }
    #subdiv {
        margin: 0 auto;
    }
    #printdiv {
        margin:2px auto;
        width:1100px;
        text-align: right;
    }
    #contentdiv {
        margin:0 auto;
        width:1100px;
        border: 1px solid lightgray;
    }
  </style>  